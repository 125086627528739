<template>
  <tr :id="guid" :class="setclass()">
    <td>
      <button
        class="btn btn-sm bg-warning"
        @click="setSelectedId($event, guid)"
        data-bs-toggle="modal"
        data-bs-target="#modaltransaction"
      >
        <font-awesome-icon icon="fa-pen-to-square" />
      </button>
    </td>

    <td :class="setclass()" v-if="guidaccount">
      {{ accDesc }}
    </td>

    <td v-if="field" :class="setclass()" style="vertical-align: middle">
      {{ field }}
    </td>
    <td v-else>&nbsp;</td>

    <td v-if="operator" :class="setclass()" style="vertical-align: middle">
      {{ operator }}
    </td>
    <td v-else>&nbsp;</td>

    <td v-if="text1" :class="setclass()" style="text-align: right">
      {{ text1 }}
    </td>
    <td v-else>&nbsp;</td>

    <td v-if="text2" :class="setclass()" style="text-align: right">
      {{ text2 }}
    </td>
    <td v-else>&nbsp;</td>

    <td v-if="text3" :class="setclass()" style="text-align: right">
      {{ text3 }}
    </td>
    <td v-else>&nbsp;</td>

    <td v-if="kontoDesc" :class="setclass()" style="vertical-align: middle">
      {{ kontoDesc }}
    </td>
    <td v-else>&nbsp;</td>

    <td>
      <button
        class="btn btn-danger btn-sm"
        @click="deleteSelectedId($event, guid)"
      >
        <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
      </button>
    </td>
  </tr>
</template>

<script>
import appUtils from '../utils/appUtils';

export default {
  emits: ['select-id', 'delete-id'],
  props: [
    'guid',
    'guidaccount',
    'operator',
    'field',
    'text1',
    'text2',
    'text3',
    'guidknt1',
    'guidknt2',
    'guidknt3',
    'guidknt4',
    'selectedtransid',
    'accDesc',
    'kontoDesc',
  ],

  data() {
    return { isActive: false };
  },

  methods: {
    formatCurrency(parNumber) {
      return appUtils.formatCurrency(parNumber);
    },

    setSelectedId(event, value) {
      this.$emit('select-id', value);
    },

    deleteSelectedId(event, value) {
      this.$emit('delete-id', value);
    },

    setclass() {
      {
        return {
          isActivejr: this.selectedtransid === this.guid ? true : false,
        };
      }
    },
  },

  computed: {
    getComputed123() {
      return '';
    },
  },
};
</script>

<style scoped></style>
