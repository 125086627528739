<template>
  <div class="text-light">
    <base-dialog
      :show="!!baseDialogMessage"
      title="Transaction saved!"
      @close="handleError"
    >
      <p class="text-warning">{{ baseDialogMessage }}</p>
    </base-dialog>

    <!-- <pre class="text-light">{{ amount }}</pre>
      <pre class="text-light">{{ amountOverride }}</pre>
  
      <pre class="text-light">{{ getDisplayPeriodic }}</pre>
      <pre class="text-light">{{ selectedDate }}</pre>
      <pre class="text-light">{{ transDataNew.value }}</pre> -->

    <div v-if="1 == 0" class="row">
      <div class="col-md-6">
        OLD
        <pre class="text-light">{{ dataOld }}</pre>
      </div>
      <div class="col-md-6">
        NEW
        <pre class="text-light">{{ dataNew }}</pre>
      </div>
    </div>

    <div class="text-warning mt-2 mb-3">
      <h4>Rules for import</h4>
    </div>

    <base-button
      class="mt-2"
      @click="addNewRec()"
      data-bs-toggle="modal"
      data-bs-target="#modaltransaction"
      >Add <font-awesome-icon icon="fa-solid fa-square-plus"
    /></base-button>

    <base-card>
      <li>
        <base-text-search v-model="searchText"></base-text-search>
      </li>
    </base-card>

    <!-- DIALOG EDIT -->
    <div
      class="modal fade"
      id="modaltransaction"
      tabindex="-1"
      aria-labelledby="vLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-warning">
            <h1 class="modal-title fs-5 text-dark" id="modaltransactionLabel">
              {{ modalTransactionCaption }}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body bg-dark text-light">
            <form @submit.prevent="">
              <!-- Account -->
              <div class="row">
                <label for="account" class="col-sm-3 col-form-label text-end"
                  >Account
                </label>

                <div class="col-sm-9">
                  <account-dropdown
                    :modelValue="selectedAcc"
                    @onchange="onChangeAccount"
                  ></account-dropdown>
                </div>
              </div>

              <!-- Field -->
              <div class="row">
                <label
                  for="field_option"
                  class="col-sm-3 col-form-label text-end"
                  >Field
                </label>

                <div class="col-sm-9">
                  <select
                    class="form-select form-select-sm"
                    id="field_option"
                    name="field_option"
                    v-model.trim="field"
                  >
                    <option
                      v-for="item in getFields"
                      :key="item.field"
                      :value="item.field"
                    >
                      {{ item.field }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Operator -->
              <div class="row">
                <label
                  class="col-sm-3 col-form-label text-end"
                  for="operator_option"
                  >Operator
                </label>
                <div class="col-sm-9">
                  <select
                    class="form-select form-select-sm"
                    id="operator_option"
                    name="operator_option"
                    v-model.trim="operator"
                  >
                    <!-- <option :key="0" :value="0"></option> -->
                    <option
                      v-for="item in getOperators"
                      :key="item.operator"
                      :value="item.operator"
                    >
                      {{ item.operator }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Text1 -->
              <div class="row">
                <label for="text1" class="col-sm-3 col-form-label text-end"
                  >Text1
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    id="text1"
                    v-model.trim="text1"
                  />
                </div>
              </div>

              <!-- Amount 1 -->
              <div class="row">
                <label for="text2" class="col-sm-3 col-form-label text-end"
                  >Amount 1
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm"
                    type="text"
                    id="text2"
                    v-model.trim="text2"
                  />
                </div>
              </div>

              <!-- Amount 2 -->
              <div class="row">
                <label for="text3" class="col-sm-3 col-form-label text-end"
                  >Amount 2
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm"
                    type="text"
                    id="text3"
                    v-model.trim="text3"
                  />
                </div>
              </div>

              <!-- Category 1 -->
              <div class="row">
                <label class="col-sm-3 col-form-label text-end" for="konto1"
                  >Category 1
                </label>

                <div class="col-sm-9">
                  <category-dropdown
                    :level="1"
                    :parentCategory="0"
                    :modelValue="selectedKonto1"
                    @on-change="onChangeComp($event, 1)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 1)"
                    id="konto1"
                    name="selectedKonto1"
                    v-model="selectedKonto1"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getKonto1"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <!-- Category 2 -->
              <div class="row">
                <label class="col-sm-3 col-form-label text-end" for="konto2"
                  >Category 2
                </label>
                <div class="col-sm-9">
                  <category-dropdown
                    :level="2"
                    :parentCategory="selectedKonto1"
                    :modelValue="selectedKonto2"
                    @on-change="onChangeComp($event, 2)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 2)"
                    id="konto2"
                    name="selectedKonto2"
                    v-model="selectedKonto2"
                  >
                    <option :key="0" :value="0"></option>

                    <option
                      v-for="item in getKonto2"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <!-- Category 3 -->
              <div class="row">
                <label class="col-sm-3 col-form-label text-end" for="konto3"
                  >Category 3
                </label>
                <div class="col-sm-9">
                  <category-dropdown
                    :level="3"
                    :parentCategory="selectedKonto2"
                    :modelValue="selectedKonto3"
                    @on-change="onChangeComp($event, 3)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 3)"
                    id="konto3"
                    name="selectedKonto3"
                    v-model="selectedKonto3"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getKonto3"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <!-- Category 4 -->
              <div class="row">
                <label
                  class="col-sm-3 col-form-label text-end align-bottom"
                  for="konto4"
                  >Category 4
                </label>
                <div class="col-sm-9">
                  <category-dropdown
                    :level="4"
                    :parentCategory="selectedKonto3"
                    :modelValue="selectedKonto4"
                    @on-change="onChangeComp($event, 4)"
                  ></category-dropdown>

                  <!-- <select
                    class="form-select form-select-sm"
                    @change="onchangejr($event, 4)"
                    id="konto4"
                    name="selectedKonto4"
                    v-model="selectedKonto4"
                  >
                    <option :key="0" :value="0"></option>
                    <option
                      v-for="item in getKonto4"
                      :key="item.guid"
                      :value="item.guid"
                    >
                      {{ item.description }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="float-end">
                <base-button class="text-end" data-bs-dismiss="modal"
                  >Cancel</base-button
                >

                <base-button
                  class="text-end"
                  data-bs-dismiss="modal"
                  data-bs-target="#modaltransaction"
                  :disabled="disableSave()"
                  @click="savePeriodic()"
                  >Save <font-awesome-icon icon="fa-floppy-disk"
                /></base-button>
              </div>

              <table v-if="1 == 0">
                <tr>
                  <td>
                    <p>{{ selectedKonto1 }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{{ selectedKonto2 }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{{ selectedKonto3 }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{{ selectedKonto4 }}</p>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END DIALOG -->

    <base-card class="bg-dark text-light"
      ><table class="table table-striped table-dark">
        <thead>
          <tr class="text-warning">
            <th>Edit</th>

            <th>
              <base-link
                :fieldName="'accDesc'"
                :fieldTitle="'Account'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th>
              <base-link
                :fieldName="'field'"
                :fieldTitle="'Field'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th>
              <base-link
                :fieldName="'operator'"
                :fieldTitle="'Operator'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th class="text-end">
              <base-link
                :fieldName="'text1'"
                :fieldTitle="'Text1'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th class="text-end">
              <base-link
                :fieldName="'text2'"
                :fieldTitle="'Amount1'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th class="text-end">
              <base-link
                :fieldName="'text3'"
                :fieldTitle="'Amount2'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th>
              <base-link
                :fieldName="'kontoDesc'"
                :fieldTitle="'Category'"
                @sort-data="sortData"
              ></base-link>
            </th>

            <th>Delete</th>
          </tr>
        </thead>

        <tbody class="align-middle">
          <!-- :idaccount="item.idaccount" -->
          <rule-item
            @select-id="selectId"
            @delete-id="deleteId"
            v-for="item in getDisplayData"
            :key="item.guid"
            :index="item.guid"
            :guid="item.guid"
            :guidaccount="item.guidaccount"
            :field="item.field"
            :operator="item.operator"
            :text1="item.text1"
            :text2="item.text2"
            :text3="item.text3"
            :guidknt1="item.guidknt1"
            :guidknt2="item.guidknt2"
            :guidknt3="item.guidknt3"
            :guidknt4="item.guidknt4"
            :selectedtransid="getSelectedGuid"
            :accDesc="item.accDesc"
            :kontoDesc="item.kontoDesc"
          ></rule-item>
        </tbody>
      </table>
    </base-card>
  </div>
</template>

<script setup>
import { ref, toRef, reactive, computed, onMounted } from 'vue';
import appUtils from '../../components/utils/appUtils.js';
import accountDropdown from '../../components/accounts/accountDropdown.vue';
import { saveData, searchData as searchDataUtil } from './utilsRules.js';
import RuleItem from '../../components/rules/RuleItem.vue';
import { useRulesStore } from '../../stores/RulesStore';
import { onChangeCategory } from '../transactions/utilsTransactions.js';
import CategoryDropdown from '../../components/categories/CategoryDropdown.vue';
import BaseLink from '../../components/ui/BaseLink.vue';

//import { useStore } from 'vuex';
//import { getDatabase } from 'firebase/database';
// import {
//   saveTransactionAtomic,
//   getGuidLastLevelKonto,
// } from './utilsTransactions.js';

const pageStore = useRulesStore();
const modalTransactionCaption = ref('Add rule transaction');

let selectedAcc = ref('0');
const text1 = ref('');
const text2 = ref('');
const text3 = ref('');
const selectedKonto1 = ref(0);
const selectedKonto2 = ref(0);
const selectedKonto3 = ref(0);
const selectedKonto4 = ref(0);
const field = ref('');
const operator = ref('');
const selectedDataGuid = ref(null);
const dataOld = reactive({});
const selectedData = toRef({});
//const store = useStore();
const baseDialogMessage = ref(null);
const dataSorting = ref('');
const dataSortingOrder = ref('asc');
const searchText = ref(null);

onMounted(() => {
  appUtils.conlog('Rules => onMounted');
  selectId();
});

async function savePeriodic() {
  searchDataLocal('');

  //saving to RULES collection
  const _new = dataNew.value;
  let _old = null;
  if (selectedDataGuid.value) _old = dataOld.value;
  //if (!_new.description) _new.description = null;
  // if (!transNew.remark1) transNew.remark1 = null;
  // if (!transNew.remark2) transNew.remark2 = null;
  // if (!transNew.remark3) transNew.remark3 = null;
  // if (!transNew.remark4) transNew.remark4 = null;
  console.log('========  transNew  ================');
  console.log(_new);
  console.log('====================================');
  saveData({
    new: _new,
    old: _old,
  })
    .then((values) => {
      console.log(values);
      searchDataLocal(values.guid);
    })
    .catch((error) => console.log(error));
}

function searchDataLocal(guid = null) {
  searchDataUtil().then(() => {
    if (guid) selectId(guid);
  });

  // const db = getDatabase();
  // store
  //   .dispatch('rules/loadRules', {
  //     db,
  //   })
  //   .then(() => {
  //     if (guid) selectId(guid);
  //   })
  //   .catch((error) => console.log(error))
  //   .finally(() => {
  //     console.log('finally search()');
  //   });
}

const dataNew = computed(function () {
  const tmp = {
    //amount: parseFloat(text1.value),
    guidaccount: selectedAcc.value ? selectedAcc.value : null,
    guidknt1: selectedKonto1.value ? selectedKonto1.value : null,
    guidknt2: selectedKonto2.value ? selectedKonto2.value : null,
    guidknt3: selectedKonto3.value ? selectedKonto3.value : null,
    guidknt4: selectedKonto4.value ? selectedKonto4.value : null,
    field: field.value,
    operator: operator.value,
    text1: text1.value,
    text2: text2.value,
    text3: text3.value,
  };
  return tmp;
});

function addNewRec() {
  modalTransactionCaption.value = 'Add Rule';
  selectedDataGuid.value = null;

  text1.value = '';
  text2.value = '';
  text3.value = '';
  field.value = '';
  // operator.value = '';
  operator.value = 'equals';
  //remark1.value = '';
}

function deleteId(guid) {
  console.log(guid);
  //deletePeriodic(transGuid);

  appUtils.deleteNode(`rules/{uid}/${guid}`).then(() => searchDataLocal());
}

function selectId(selectedGuid) {
  const data = getDisplayData;

  if (!selectedGuid) return;

  for (var i = 0; i < data.value.length; i++) {
    if (data.value[i].guid === selectedGuid) {
      console.log('selectId => found:', selectedGuid);

      dataOld.value = data.value[i];

      if (typeof dataOld.value.guidknt1 === 'undefined')
        dataOld.value.guidknt1 = null;
      if (typeof dataOld.value.guidknt2 === 'undefined')
        dataOld.value.guidknt2 = null;
      if (typeof dataOld.value.guidknt3 === 'undefined')
        dataOld.value.guidknt3 = null;
      if (typeof dataOld.value.guidknt4 === 'undefined')
        dataOld.value.guidknt4 = null;

      selectedData.value = data.value[i];

      text1.value = selectedData.value.text1;
      text2.value = selectedData.value.text2;
      text3.value = selectedData.value.text3;
      operator.value = selectedData.value.operator;
      field.value = selectedData.value.field;
      selectedDataGuid.value = selectedData.value.guid;
      modalTransactionCaption.value =
        'Edit Rule [' + selectedDataGuid.value + ']';

      selectedAcc.value = selectedData.value.guidaccount;
      selectedKonto1.value = selectedData.value.guidknt1;
      selectedKonto2.value = selectedData.value.guidknt2;
      selectedKonto3.value = selectedData.value.guidknt3;
      selectedKonto4.value = selectedData.value.guidknt4;
      //remark1.value = selectedData.value.remark1;
      break;
    }
  }
}

function onChangeComp(eventArgs, level) {
  //appUtils.conlog(eventArgs); // .value);

  onChangeCategory(
    eventArgs,
    level,
    selectedKonto1,
    selectedKonto2,
    selectedKonto3,
    selectedKonto4
  );
}

// function onchangejr(eventArgs, par) {
//   //appUtils.conlog(eventArgs.target.text);
//   if (par === 1) {
//     selectedKonto2.value = 0;
//     selectedKonto3.value = 0;
//     selectedKonto4.value = 0;
//   } else if (par === 2) {
//     selectedKonto3.value = 0;
//     selectedKonto4.value = 0;
//   } else if (par === 3) {
//     selectedKonto4.value = 0;
//   }
// }

function disableSave() {
  return false;
}

const getSelectedGuid = computed(function () {
  if (selectedDataGuid.value) {
    return selectedDataGuid.value;
  } else {
    return '';
  }
});

function onChangeAccount(value) {
  selectedAcc.value = value;
}

const getOperators = computed(function () {
  let tmp = [];

  tmp.push({ operator: 'equals' });
  tmp.push({ operator: 'includes' });

  return tmp;
});

const getFields = computed(function () {
  let tmp = [];

  tmp.push({ field: 'description' });
  tmp.push({ field: 'remark2' });

  return tmp;
});

const getDisplayData = computed(function () {
  //const rulesStore = useRulesStore();
  let tmp = pageStore.rulesGuid;
  if (searchText.value) tmp = fastFilterRules(tmp, searchText.value);
  // console.log('getDisplayPeriodic');
  // console.log(tmp);
  return tmp;
});

function fastFilterRules(tmp, searchText) {
  const fieldArr = ['field', 'accDesc', 'kontoDesc', 'operator', 'text1'];

  if (searchText.length <= 2) {
    return tmp.filter(() => {
      return true;
    });
  } else {
    return tmp.filter(
      appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
    );
  }
}

function handleError() {
  baseDialogMessage.value = null;
}

function sortData(field) {
  appUtils.setSortingParams(field, dataSorting, dataSortingOrder, pageStore);
}

// function sortTransactions(field) {
//   // implementing function just not to get an error
//   return field;
// }
</script>
