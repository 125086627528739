import {
  child,
  getDatabase,
  push,
  ref as reffb,
  update,
} from 'firebase/database';

import appUtils from '../../components/utils/appUtils.js';
import config from '../../store/global.js';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useRulesStore } from '../../stores/RulesStore';

export async function searchData() {
  console.log('searchData');

  let promise = new Promise(function (resolve, reject) {
    try {
      const db = getDatabase();

      const rulesStore = useRulesStore();
      rulesStore
        .loadRules({
          db,
        })
        // store
        //   .dispatch('rules/loadRules', {
        //     db,
        //   })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(new Error(error));
          console.log(error);
        })
        .finally(() => {
          console.log('finally search()');
        });
    } catch (error) {
      //appUtils.conlog(error);
      reject(new Error(error.message));
    }
  });
  return promise;
}

export async function saveData(data) {
  data.action = 'update';
  if (data.old == null) {
    data.action = 'insert';
  }

  let promise = new Promise(function (resolve, reject) {
    try {
      appUtils.conlog('rules => saveData');

      const firebaseConfig = config.global().firebaseConfig;

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const db = getDatabase(app);
      const auth = getAuth();
      let userUid = auth.currentUser.uid;
      let guidKey = null;

      if (data.action == 'update') {
        //existing item
        guidKey = data.old.guid;
      } else {
        guidKey = push(child(reffb(db), 'rules')).key;
      }

      //------------------------------------------------
      //-- dealing with PERIODIC trans. insert/update --
      //------------------------------------------------
      const updates = {};
      updates['rules/' + userUid + '/' + guidKey] = data.new;

      update(reffb(db), updates).then(() => {
        resolve({ guid: guidKey });
      });
    } catch (error) {
      //appUtils.conlog(error);
      reject(new Error(error.message));
    }
  });
  return promise;
}
